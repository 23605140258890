import { v4 as uuidv4, validate as uuidValidate } from "uuid";
import { useMainStore } from "~/store";

export default defineNuxtRouteMiddleware((to, from) => {
  const store = useMainStore();
  const country = store.country;
  const locale = store.locale;
  const app = useNuxtApp();
  const $config = useRuntimeConfig();

  // If request path are from index, ignore it
  if (["/", ""].includes(to.path)) {
    return;
  }

  const cartCookie = app.$cookies.get($config.CART_COOKIE_NAME);
  const createCookieFor = (locale, cartToken) =>
    app.$cookies.set($config.CART_COOKIE_NAME, cartToken, {
      path: `/${country}-${locale}`,
      secure: process.env.NODE_ENV === "production",
      sameSite: "lax",
    });

  if (!cartCookie || (cartCookie && !uuidValidate(cartCookie.split("_")[1]))) {
    const cartToken = `${country}_${uuidv4()}`;

    // default cart cookie (english)
    createCookieFor("en", cartToken);

    // localized cart cookie
    if (locale !== "en") {
      createCookieFor(locale, cartToken);
    }
  }
});